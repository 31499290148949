<template>
  <div class="header_container">
    <div class="header">
      <div class="w">
        <div class="header_left">
          <div class="inner_left_box" @click="jumpLink('/home')">
            <div class="logo">
              <img src="../../assets/logo.png" alt="">
            </div>
            <div class="shjs_name">
              中国上海建设网
            </div>
            <div class="line"></div>
          </div>
          <div class="inner_right_box">
            <img class="icon_phone" src="../../assets//img/icon/icon_phone.png" alt="">
            <div class="service_phone_text">
              <span class="service_phone_title">服务热线:</span>
              <span class="service_phone">{{ service_tel }}</span>
            </div>
          </div>
        </div>
        <div class="header_right">
          <div class="workbench" @click="jumpLink('/workbench')">
            工作台
          </div>
          <el-dropdown v-if="userInfo.id" @command="handleCommand">
            <div class="user_info_box">
              <div class="user_img">
                <img :src="userInfo.avatar" alt="" v-if="userInfo.avatar">
                <img src="../../assets/img/icon/img_basicInfo_avatar.png" alt="" v-else>
              </div>
              <div class="user_nickname">
                <span v-if="userInfo.nickname">{{ userInfo.nickname }}</span>
                <span v-else>默认昵称</span>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="/personalCenter">个人中心</el-dropdown-item>
              <el-dropdown-item command="/login">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="login_or_sigin" v-else @click="jumpLink('/login')">
            注册/登录
          </div>
        </div>
      </div>
    </div>
    <div class="header_bottom_box">

      <div class="b_w">
        <div class="big_logo" @click="jumpLink('/home')">
          <img src="../../assets/img/home/b_logo.png" alt="">
        </div>
        <div class="search_big_box">
          <div class="search_box">
            <div class="inner_left_input_box">
              <img src="../../assets/img/icon/icon_search.png" class="icon_search" alt="">
              <el-input type="text" placeholder="请输入你要搜索的内容" v-model="keyword" clearable />
            </div>
            <div class="search_btn" @click="searchFunc">
              搜索
            </div>
          </div>
          <div class="send_or_enter">
            <div class="s_menu_item" @click="jumpLink('/workbench/publishBids')">
              <img src="../../assets/img/icon/icon_send_needing.png" class="s_menu_item_icon" alt="">
              <span>
                发布需求
              </span>
            </div>
            <div class="s_menu_item" @click="jumpLink('/personalCenter/enterpriseAuth')">
              <img src="../../assets/img/icon/icon_ep_enter.png" class="s_menu_item_icon" alt="">
              <span>
                企业认证
              </span>
            </div>
          </div>
        </div>
        <nav class="topNav">
          <ul>
            <li @click="jumpLink(item.router,'closeSearch')" v-for="item in navList" :key="item.id" :class="[item.router==curRouter?'activeLine':'']">
              <img :src="item.icon" class="menu_icon" alt="">
              <span> {{ item.name }}</span>
            </li>
            <div class="activeLine"></div>
          </ul>
        </nav>
      </div>

    </div>
    <div class="search_tabs w" v-if="$store.state.search_tabs_show&&filterRouter(cur_full_router_path)">
      <nav class="searchNav">
        <ul>
          <li @click="jumpLink(item.router)" v-for="item in search_tabs" :key="item.id" :class="[item.router==curRouter?'activeLine':'']">
            <span> {{ item.name }}</span>
          </li>
          <div class="activeLine"></div>
        </ul>
      </nav>
    </div>
  </div>

</template>

<script>
import { mapMutations } from 'vuex';
export default {
  data () {
    //
    return {
      curRouter: '/home',
      navList: [
        { id: 1, router: "/home", name: "首页", icon: require('../../assets/img/icon/icon_home.png') },
        { id: 2, router: "/callBids", name: "招标信息", icon: require('../../assets/img/icon/icon_callBids.png') },
        { id: 3, router: "/getBids", name: "中标信息", icon: require('../../assets/img/icon/icon_getBids.png') },
        { id: 4, router: "/recruitJob", name: "招工找活", icon: require('../../assets/img/icon/icon_recruitJob.png') },
        { id: 5, router: "/serviceSubcontract", name: "劳务分包", icon: require('../../assets/img/icon/icon_serviceSubcontract.png') },
        { id: 7, router: "/machineRent", name: "机械租赁", icon: require('../../assets/img/icon/icon_machineRent.png') },
        { id: 8, router: "/proSupply", name: "产品供应", icon: require('../../assets/img/icon/icon_proSupply.png') },
        { id: 9, router: "/recruitInfo", name: "招采信息", icon: require('../../assets/img/icon/icon_recruitInfo.png') },
      ],
      search_tabs: [
        { id: 2, router: "/callBids", name: "招标", },
        { id: 3, router: "/getBids", name: "中标", },
        { id: 4, router: "/recruitJob", name: "招工找活", },
        { id: 5, router: "/serviceSubcontract", name: "分包", },
        { id: 7, router: "/machineRent", name: "机械", },
        { id: 8, router: "/proSupply", name: "产品", },
        { id: 9, router: "/recruitInfo", name: "招采", },
      ],
      userInfo: {},
      keyword: '',
      service_tel: '',
      cur_full_router_path: ''
    };
  },
  mounted () {
    this.getUserInfo();
    this.changeCur(this.$route.path)
  },
  methods: {
    ...mapMutations(['SET_KEYWORD']),
    //个人用户下拉菜单
    handleCommand (command) {
      switch (command) {
        case '/personalCenter':
          this.jumpLink(command);
          break;
        case '/login':
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('qiunu_token');
          this.userInfo = {};
          this.jumpLink('/home');
          break;
      }
    },
    jumpLink (router, type) {
      if (type) {
        this.SET_KEYWORD({ keyword: '', show: false });
        this.keyword = ''
        this.$forceUpdate();
      } else {
        this.SET_KEYWORD({ keyword: this.keyword, show: true });
      }
      this.curRouter = router;
      this.$router.push(router);
    },
    changeCur (path) {
      console.log(path, 'dagaga');
      let cur_path = '';
      this.navList.forEach((item) => {
        if (path.includes(item.router)) {
          cur_path = item.router;
          return;
        }
      });
      this.curRouter = cur_path;
    },
    changeFullPath (path) {
      let cur_path = '';
      this.search_tabs.forEach((item) => {
        if (item.router == path) {
          cur_path = path;
          return;
        }
      });
      this.cur_full_router_path = cur_path;
    },
    getUserInfo () {
      let userInfo = localStorage.getItem('userInfo');
      this.service_tel = localStorage.getItem('service_tel');
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo);
      }
    },
    searchFunc () {
      if (!this.filterRouter(this.cur_full_router_path)) {
        this.$router.push('/callBids');
      }
      this.SET_KEYWORD({ keyword: this.keyword, show: true });
      this.$forceUpdate();
    },
    filterRouter (router) {
      console.log('比较router', router, this.cur_full_router_path);
      let findObj = this.search_tabs.find(item => item.router == router);
      if (findObj && findObj.router) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    $route (val) {
      this.changeCur(val.path);
      this.changeFullPath(val.path);
      console.log(val, this.cur_full_router_path, 'dnaigoaga');

    },
  },
};
</script>

<style lang='scss'>
.header_container {
  background-color: #f4f6fa;
  .header {
    height: 60px;
    background: #203152;
    .w {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_left {
        display: flex;
        align-items: center;
        height: 40px;
        .inner_left_box {
          display: flex;
          align-items: center;
          cursor: pointer;
          .logo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            background: #3f63ff;
            text-align: center;
            line-height: 40px;
            margin-right: 11px;
          }
          .shjs_name {
            font-size: 14px;
            font-family: HarmonyOS Sans SC-Medium, HarmonyOS Sans SC;
            font-weight: 500;
            color: #ffffff;
          }
          .line {
            width: 1px;
            height: 19px;
            opacity: 1;
            border: 1px solid #43567b;
            margin: 0 20px;
          }
        }
        .inner_right_box {
          display: flex;
          align-items: center;
          .icon_phone {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
          .service_phone_text {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            .service_phone_title {
              margin-right: 4px;
            }
            .service_phone {
            }
          }
        }
      }
      .header_right {
        display: flex;
        align-items: center;
        .workbench {
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
          margin-right: 34px;
        }
        .login_or_sigin {
          width: 82px;
          height: 30px;
          background: #3f63ff;
          border-radius: 8px 8px 8px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
        .user_info_box {
          display: flex;
          align-items: center;
          cursor: pointer;
          .user_img {
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            margin-right: 4px;
          }
          .user_nickname {
            color: #ffffff;
            font-size: 14px;
          }
        }
      }
    }
  }
  .header_bottom_box {
    background-color: #f4f6fa;

    .b_w {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .big_logo {
        width: 140px;
        height: 125px;
        position: absolute;
        top: 30px;
        left: -130px;
        cursor: pointer;
      }
    }
    .search_big_box {
      width: 100%;
      padding: 33px 24px 52px 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search_box {
        display: flex;
        align-content: center;
        justify-content: space-between;
        width: 670px;
        height: 50px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 6px 0px 0px 6px;
        opacity: 1;

        .inner_left_input_box {
          display: flex;
          flex: 1;
          align-items: center;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          border-left: 1px solid #e5e5e5;
          padding: 16px 14px;
          box-sizing: border-box;
          height: 50px;
          border-radius: 6px 0px 0px 6px;

          .icon_search {
            flex: none;
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
          input {
            flex: 1;
            font-size: 15px;
            border: none;
          }
        }
        .search_btn {
          width: 107px;
          flex: none;
          height: 50px;
          box-sizing: border-box;
          background: #3f63ff;
          border-radius: 0px 6px 6px 0px;
          border: 1px solid #3f63ff;
          text-align: center;
          line-height: 50px;
          color: #ffffff;
          font-size: 15px;
          cursor: pointer;
        }
      }
      .send_or_enter {
        display: flex;
        align-items: center;

        .s_menu_item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 138px;
          height: 45px;
          background: linear-gradient(90deg, #e3f7fa 0%, #ebebff 100%);
          opacity: 1;
          border: 1px solid #8dc8ff;
          border-radius: 8px;
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          margin-right: 14px;
          cursor: pointer;
          .s_menu_item_icon {
            width: 18px;
            height: 18px;
            margin-right: 2px;
          }
          span {
          }
        }
      }
    }
    .topNav {
      padding-bottom: 17px;
      ul {
        display: flex;
        align-items: center;
        li {
          width: fit-content;
          flex: none;
          margin-left: 50px;
          font-size: 18px;
          display: flex;
          align-items: center;
          color: #333333;
          .menu_icon {
            width: 24px;
            height: 24px;
          }
          cursor: pointer;
        }
      }
      .activeLine {
        position: relative;
        color: #3f63ff;
        &::before {
          content: "";
          position: absolute;
          bottom: -17px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #3f63ff;
          border-radius: 2px 2px 2px 2px;
        }
      }
    }
  }
  .search_tabs {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-top: 10px;
    padding: 19px 0 15px;
    .searchNav {
      ul {
        display: flex;
        align-items: center;
        li {
          width: fit-content;
          flex: none;
          margin-left: 50px;
          font-size: 18px;
          display: flex;
          align-items: center;
          color: #333333;
          .menu_icon {
            width: 24px;
            height: 24px;
          }
          cursor: pointer;
        }
      }
      .activeLine {
        position: relative;
        color: #3f63ff;
        &::before {
          content: "";
          position: absolute;
          bottom: -17px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #3f63ff;
          border-radius: 2px 2px 2px 2px;
        }
      }
    }
  }
}
</style>
