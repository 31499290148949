import { initRequest } from '../http/index.js';
const api = {};

//获取首页公共信息
api.getHomeInfo = (parmas) => initRequest(`/api/index/index`, parmas);
//获取文档
api.getDocument = (parmas) => initRequest(`/api/common/document`, parmas, false);
//获取七牛云上传token 
api.getQiniuToken = (parmas) => initRequest(`/api/common/getQiniuToken`, parmas, false);
//获取公共分类数据 
api.getCategoryConfig = (parmas) => initRequest(`/api/common/getCategoryConfig`, parmas, false);
//.常见问题列表
api.getQuestionList = (parmas) => initRequest(`/api/common/getQuestionList`, parmas, false);
//.常见问题详情
api.getQuestionDetail = (parmas) => initRequest(`/api/common/getQuestionDetail`, parmas, false);
//新闻列表
api.getNewsList = (parmas) => initRequest(`/api/common/getNewsList`, parmas, false);
//新闻详情
api.getNewsDetail = (parmas) => initRequest(`/api/common/getNewsDetail`, parmas, false);

//获取会员信息、会员权限配置、会员商品
api.getVipConfigProduct = (parmas) => initRequest(`/api/vip/getVipConfigProduct`, parmas, false);
//会员支付
api.createOrder = (parmas) => initRequest(`/api/vip/createOrder`, parmas, false);
//获取vip订单
api.getOrderList = (parmas) => initRequest(`/api/vip/getOrderList`, parmas, false);
//查询订单支付
api.checkOrderStatus = (parmas) => initRequest(`/api/vip/checkOrderStatus`, parmas, false);


//收藏-产品列表 
api.getCollectProductList = (parmas) => initRequest(`/api/collection/getProductList`, parmas);
//收藏-机械租售列表
api.getCollectMechaniseList = (parmas) => initRequest(`/api/collection/getMechaniseList`, parmas);
//收藏-招采列表 
api.getCollectPurchaseList = (parmas) => initRequest(`/api/collection/getPurchaseList`, parmas);
//收藏-分包列表
api.getCollectPackageList = (parmas) => initRequest(`/api/collection/getPackageList`, parmas,);
//收藏-简历列表
api.getCollectResumeList = (parmas) => initRequest(`/api/collection/getResumeList`, parmas,);
//收藏-招工列表 
api.getCollectJobList = (parmas) => initRequest(`/api/collection/getJobList`, parmas,);
//公共收藏、取消收藏接口
api.collect = (parmas) => initRequest(`/api/collection/collect`, parmas,);




//发送短信验证码
api.sendSms = (parmas) => initRequest(`/api/member/sendSms`, parmas, false);
//验证码登录
api.codeLogin = (parmas) => initRequest(`/api/member/codeLogin`, parmas, false);
//获取个人信息
api.getMemberInfo = (parmas) => initRequest(`/api/member/getMemberInfo`, parmas, false);
//.修改用户信息
api.editMemberInfo = (parmas) => initRequest(`/api/member/editMemberInfo`, parmas, false);
//修改手机号
api.resetMobile = (parmas) => initRequest(`/api/member/resetMobile`, parmas, false);
//意见反馈
api.suggestion = (parmas) => initRequest(`/api/member/suggestion`, parmas, false);
//获取个人实名认证信息
api.getPersonalInfo = (parmas) => initRequest(`/api/member/getPersonalInfo`, parmas, false);
//提交个人实名认证
api.personalAuth = (parmas) => initRequest(`/api/member/personalAuth`, parmas, false);
//.获取企业认证信息
api.getCompanyInfo = (parmas) => initRequest(`/api/member/getCompanyInfo`, parmas, false);
//.提交企业认证
api.companyAuth = (parmas) => initRequest(`/api/member/companyAuth`, parmas, false);
//获取系统消息分类
api.getSysMsgCount = (parmas) => initRequest(`/api/member/getSysMsgCount`, parmas, false);
//系统消息列表
api.getMsgList = (parmas) => initRequest(`/api/member/getMsgList`, parmas, false);
//统一判断用户操作权限
api.checkMemberDealAuth = (parmas) => initRequest(`/api/member/checkMemberDealAuth`, parmas, false);


//发布招标信息 
api.publishInvitation = (parmas) => initRequest(`/api/invitation/publish`, parmas, false);
//我的招标列表 
api.getMyInvitation = (parmas) => initRequest(`/api/invitation/getMyInvitation`, parmas, false);
//招标列表 
api.getInvitationList = (parmas) => initRequest(`/api/invitation/getInvitationList`, parmas, false);
//.招标详情 
api.getInvitationDetail = (parmas) => initRequest(`/api/invitation/getInvitationDetail`, parmas, false);



//发布中标信息
api.publishWinBids = (parmas) => initRequest(`/api/win/publish`, parmas, false);
//我的中标列表
api.getMyWin = (parmas) => initRequest(`/api/win/getMyWin`, parmas, false);
//中标列表
api.getWinList = (parmas) => initRequest(`/api/win/getWinList`, parmas, false);
//中标详情
api.getWinDetail = (parmas) => initRequest(`/api/win/getWinDetail`, parmas, false);

//发布招中标信息 
api.publishBids = (type, parmas) => initRequest(`/api/${type}/publish`, parmas, false);





//发布招工信息 
api.publishJob = (parmas) => initRequest(`/api/job/publish`, parmas, false);
//我的招工列表 
api.getMyJob = (parmas) => initRequest(`/api/job/getMyJob`, parmas, false);
//暂停招工
api.breakJob = (parmas) => initRequest(`/api/job/breakJob`, parmas, false);
//开始招工
api.unBreakJob = (parmas) => initRequest(`/api/job/unBreakJob`, parmas, false);
//我要找活-招工列表
api.getJobList = (parmas) => initRequest(`/api/job/getJobList`, parmas, false);
//.获取简历
api.getMyResume = (parmas) => initRequest(`/api/job/getMyResume`, parmas, false);
//编辑简历
api.editResume = (parmas) => initRequest(`/api/job/editResume`, parmas, false);
//招工详情
api.getJobDetail = (parmas) => initRequest(`/api/job/getJobDetail`, parmas,);
//投递简历
api.sendResume = (parmas) => initRequest(`/api/job/sendResume`, parmas,);
//查看投递简历列表
api.getResumeSendList = (parmas) => initRequest(`/api/job/getResumeSendList`, parmas,);
//我要招人-简历列表
api.getResumeList = (parmas) => initRequest(`/api/job/getResumeList`, parmas,);
//我的投递列表 
api.getMySendList = (parmas) => initRequest(`/api/job/getMySendList`, parmas,);
//.我要招人-简历详情
api.getResumeDetail = (parmas) => initRequest(`/api/job/getResumeDetail`, parmas,);
//.修改投递记录为已阅
api.scanResume = (parmas) => initRequest(`/api/job/scanResume`, parmas, false, true);





//获取分包详情
api.getPackageDetail = (parmas) => initRequest(`/api/package/getPackageDetail`, parmas,);
//获取分包电话
api.getPackagePhone = (parmas) => initRequest(`/api/package/getPackagePhone`, parmas,);
//筛选分包列表
api.getPackageList = (parmas) => initRequest(`/api/package/getPackageList`, parmas,);
//下架、上架接口
api.downPackage = (parmas) => initRequest(`/api/package/downPackage`, parmas,);
//查看我的分包列表
api.getMyPackage = (parmas) => initRequest(`/api/package/getMyPackage`, parmas,);
//发布劳务分包信息
api.publishPackage = (parmas) => initRequest(`/api/package/publish`, parmas,);



//我的报价详情
api.getMyPriceDetail = (parmas) => initRequest(`/api/purchase/getMyPriceDetail`, parmas,);
//我的采购-查看报价
api.getPriceList = (parmas) => initRequest(`/api/purchase/getPriceList`, parmas,);
//我的报价列表
api.getMyPriceList = (parmas) => initRequest(`/api/purchase/getMyPriceList`, parmas,);
//立即报价
api.sendPrice = (parmas) => initRequest(`/api/purchase/sendPrice`, parmas,);
//获取招采详情
api.getPurchaseDetail = (parmas) => initRequest(`/api/purchase/getPurchaseDetail`, parmas,);
//获取招采电话
api.getPurchasePhone = (parmas) => initRequest(`/api/purchase/getPurchasePhone`, parmas,);
//筛选招采列表
api.getPurchaseList = (parmas) => initRequest(`/api/purchase/getPurchaseList`, parmas,);
//下架、上架接口
api.downPurchase = (parmas) => initRequest(`/api/purchase/downPackage`, parmas,);
//查看我的招采列表
api.getMyPurchase = (parmas) => initRequest(`/api/purchase/getMyPurchase`, parmas,);
//发布招采信息
api.publishPurchase = (parmas) => initRequest(`/api/purchase/publish`, parmas,);


//获取机械租售详情 
api.getMechaniseDetail = (parmas) => initRequest(`/api/mechanise/getMechaniseDetail`, parmas);
//获取机械租售电话
api.getMechanisePhone = (parmas) => initRequest(`/api/mechanise/getPurchasePhone`, parmas);
//筛选机械租售列表
api.getMechaniseList = (parmas) => initRequest(`/api/mechanise/getMechaniseList`, parmas);
//下架、上架接口
api.downMechanise = (parmas) => initRequest(`/api/mechanise/downMechanise`, parmas);
//查看我的机械租售列表
api.getMyMechanise = (parmas) => initRequest(`/api/mechanise/getMyMechanise`, parmas);
//发布机械信息
api.publishMechanise = (parmas) => initRequest(`/api/mechanise/publish`, parmas);



//筛选企业列表 
api.getCompanyList = (parmas) => initRequest(`/api/product/getCompanyList`, parmas);
//获取企业详情 
api.getCompanyDetail = (parmas) => initRequest(`/api/product/getCompanyDetail`, parmas);
//获取推荐企业
api.getHomeCompany = (parmas) => initRequest(`/api/product/getHomeCompany`, parmas);
//获取产品详情 
api.getProductDetail = (parmas) => initRequest(`/api/product/getProductDetail`, parmas);
//获取产品电话
api.getProductPhone = (parmas) => initRequest(`/api/product/getProductPhone`, parmas);
//筛选产品列表
api.getProductList = (parmas) => initRequest(`/api/product/getProductList`, parmas);
//下架、上架接口
api.downProduct = (parmas) => initRequest(`/api/product/downProduct`, parmas);
//查看我的产品列表
api.getMyProductList = (parmas) => initRequest(`/api/product/getMyProductList`, parmas);
//.发布产品信息
api.publishProduct = (parmas) => initRequest(`/api/product/publish`, parmas);





export default api;
