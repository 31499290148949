import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import API from './api/api';
import store from './store'
import auth from "./mixin/auth.js"
import $mReg from './common/js/reg.js'
import Image from './components/Image/index.vue'
import AuthPassPopup from './components/authPassPopup/authPassPopup.vue';
import BigOpenVip from './components/vipPopup/bigOpenVip.vue';

Vue.config.productionTip = false
Vue.prototype.$regs = $mReg;
//高德地图引入
Vue.prototype.$api = API;
Vue.mixin(auth);

Vue.component('AuthPassPopup', AuthPassPopup);
Vue.component('BigOpenVip', BigOpenVip);
Vue.component('IImage', Image);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')