import { mapMutations } from "vuex";
export default {
  data () {
    return {
      vipShow: false,
      approveShow: false,
      auth: '',
      teamOrBuild: {
        1: '个人',
        2: '班组',
        3: '施工队'
      },
      workStatus: {
        1: '找活中',
        2: '干活中'
      },
      deal_type_list: {
        1: '出租',
        2: '出售',
        3: '求租',
        4: '求售'
      },
      pro_tax_type: {
        0: '含税',
        1: '不含税'
      }
    }
  },

  methods: {
    ...mapMutations(['SET_APPROVE_SHOW', 'SET_VIP_SHOW']),
    checkMemberDealAuth (action) {
      return new Promise((reslove, reject) => {
        this.$api.checkMemberDealAuth({
          action
        }).then(res => {
          this.auth = res.data.auth;
          switch (res.data.auth) {
            case 'vip':
              this.$message.error('该操作需要开通VIP!');
              this.SET_VIP_SHOW(true);
              break;
            case 'company':
              this.SET_APPROVE_SHOW(true);
              break;
            default:
              reslove({ code: 1 });
              break;
          }

        });
      })
    },
    replaceWithAsterisks (inputString) {
      var replacedString = inputString.replace(/./g, '*');
      return replacedString;
    },
    goLink (router) {
      this.$router.push(router);
    }
  }
}
