<template>
  <div class="footer">
    <div class="w">
      <div class="footer_left_box">
        <div class="footer_menu_box">
          <div class="footer_menu_title">
            平台协议
          </div>
          <div class="footer_menu_list">
            <div class="footer_menu_item" v-for="item in agreement_list" :key="item.name" @click="jumpLink(item.link)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="footer_menu_box">
          <div class="footer_menu_title">
            常见问题
          </div>
          <div class="footer_menu_list">
            <div class="footer_menu_item" v-for="item in list_data.lists" :key="item.title" @click="goDetails(item.id)">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="contact_box">
          <div class="contact_item">
            <div class="contact_title">
              咨询电话：
            </div>
            <div class="contact_text">
              17608133074
            </div>
          </div>
          <div class="contact_item">
            <div class="contact_title">
              邮箱：
            </div>
            <div class="contact_text">
              18621305888@163.com
            </div>
          </div>
        </div>
      </div>
      <div class="footer_right_box">
        <div class="code_box">
          <img class="code_img" src="../../assets/img/home/er_code_weixin.jpg" alt="">
          <div class="code_text_box">
            <p>扫一扫</p>
            <p>关注公众号</p>
          </div>
        </div>
        <div class="code_box">
          <img class="code_img" src="../../assets/img/home/er_code_uniapp.jpg" alt="">
          <div class="code_text_box">
            <p>扫一扫</p>
            <p>打开小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      Copyright © 上海悍辉建设集团有限公司 沪ICP备2023018048号-1
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin';
import { mapMutations } from 'vuex';
export default {
  mixins: [mixin],
  data () {
    return {
      agreement_list: [
        { name: '用户注册协议', link: '/userAgreement' },
        { name: '用户隐私政策', link: '/privacyAgreement' },
      ],
      page: {
        page_size: 2,
        page: 1,
      },
    };
  },
  mounted () {
    this.getData();
  },
  methods: {
    ...mapMutations(['SET_QA_DETAILS_SHOW']),
    jumpLink (router) {
      this.$router.push(router);
    },
    getData () {
      this.getList('getQuestionList', this.page);
    },
    goDetails (id) {
      this.SET_QA_DETAILS_SHOW(true);
      this.$router.push({
        path: '/personalCenter/QA/details',
        query: {
          id
        }
      })
    }
  },
};
</script>

<style lang="scss">
.footer {
  // height: 250px;
  // box-sizing: border-box;
  background: #fff;
  padding: 60px 0 27px;
  .w {
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    .footer_left_box {
      display: flex;
      .footer_menu_box {
        .footer_menu_title {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 14px;
        }
        .footer_menu_list {
          .footer_menu_item {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 11px;
            margin-right: 68px;
            cursor: pointer;
            &:hover {
              color: #0c67cf;
            }
          }
        }
      }
      .contact_box {
        .contact_item {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-bottom: 20px;
          .contact_title {
          }
          .contact_text {
            color: #333;
          }
        }
      }
    }
    .footer_right_box {
      display: flex;
      .code_box {
        text-align: center;
        margin-right: 17px;
        .code_img {
          display: block;
          width: 106px;
          height: 106px;
          margin-bottom: 6px;
        }
        .code_text_box {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .copyright {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
}
</style>
