<template>
  <div id="app">
    <header v-if="curRouter!='/login'">
      <Header />
    </header>
    <div class="container">
      <router-view></router-view>
      <div v-if="$store.state.isShowLoading" class="loading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <footer>
      <Footer />
    </footer>
    <AuthPassPopup :visible.sync="$store.state.approveShow"></AuthPassPopup>
    <BigOpenVip :isFull="true" :visible.sync="$store.state.vipShow"></BigOpenVip>
  </div>
</template>

<script>
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      curRouter: '/login',
    }
  },
  created () {
    this.getCategoryConfig();
  },
  watch: {
    $route (route) {
      this.curRouter = route.path;
    }
  },
  methods: {
    getCategoryConfig () {
      this.$api.getCategoryConfig().then(res => {
        if (res.data) {
          let commonCateGoryConfig = JSON.stringify(res.data);
          localStorage.setItem('commonCateGoryConfig', commonCateGoryConfig);
        }
      })
    }
  }
}
</script>

<style lang='scss'>
@import "./common/css/common.scss";
* {
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
  height: 100%;
}
/* 定义滚动条的宽度和高度 */
::-webkit-scrollbar {
  width: 6px; /* 滚动条的宽度 */
  height: 6px; /* 滚动条的高度 */
}

/* 定义滚动条的轨道背景颜色 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 定义滚动条的滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* 定义滚动条的滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
#app {
  display: flex;
  flex-direction: column;
  color: #333333;
  background-color: #f4f6fa;
  height: 100%;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3f63ff;
    font-size: 30px;
  }
  header {
    flex: none;
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 9;
  }
  .container {
    flex: 1;
    background-color: #f4f6fa;
    .w {
      width: 1200px;
      margin: 0 auto;
    }
  }
  footer {
    flex: none;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  color: #333333;
  font-size: 14px;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #333333;
  text-decoration: none;
  font-size: 14px;
}

button {
  border: none;
  outline: none;
  text-align: center;
}

input {
  outline: none;
  border: none;
}

html {
  font-size: 18px !important;
}

img {
  display: block;
}
@font-face {
  font-family: "PingFang";
  src: url("./assets/font/PingFang.otf");
}

body {
  font-family: "PingFang" !important;
}
</style>
