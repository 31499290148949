import Pager from '../components/pager/Pager.vue'
export default {
  components: {
    Pager
  },
  onLoad () {
  },
  data () {
    return {
      qiniu_url: 'http://image.zgshjsw.cn',
      page: {
        page_size: 10,
        page: 1,
      },
      total: 0,
      list_data: {
        lists: [],
        total: 0,
      },
    }
  },
  methods: {
    del_image_url (url) {
      if (Array.isArray(url)) {
        let newUrlArr = url.map(item => {
          return item.replace(this.qiniu_url, '');
        })
        return newUrlArr;
      } else {
        let newUrl = url.replace(this.qiniu_url, '');
        return newUrl;
      }
    },
    goLink (link) {
      this.$router.push(link);
    },
    add_image_url (url) {
      if (Array.isArray(url)) {
        let newUrlArr = url.map(item => {
          return this.qiniu_url + item;
        })
        return newUrlArr;
      } else {
        let newUrl = this.qiniu_url += url;
        return newUrl;
      }
    },
    changePage (page) {
      this.page = page;
    },
    getList (api, page, params) {
      return new Promise((reslove, reject) => {
        this.$api[api]({ ...page, ...params }).then(res => {
          if (res.code == 1) {
            this.list_data.lists = res.data.lists;
            this.list_data.total = res.data.total_num;
            reslove(res.data);
          }
        });
      })
    },
    getDateWithOffset (offset) {
      const today = new Date();
      today.setDate(today.getDate() - offset);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
