import Vue from "vue";
import {
  Button,
  Avatar,
  Input,
  Upload,
  Cascader,
  Select,
  Tabs,
  Collapse,
  CollapseItem,
  Form,
  FormItem,
  Option,
  Loading,
  Pagination,
  Message,
  Radio,
  RadioGroup,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Empty,
  DatePicker,
  MessageBox,
  Carousel,
  CarouselItem,
  Popover,
  Dialog
} from "element-ui";
Vue.use(Button)
  .use(Avatar)
  .use(Input)
  .use(Pagination)
  .use(Upload)
  .use(Cascader)
  .use(Select)
  .use(Collapse)
  .use(CollapseItem)
  .use(Form)
  .use(FormItem)
  .use(Option)
  .use(Radio)
  .use(RadioGroup)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Empty)
  .use(Dropdown)
  .use(DatePicker)
  .use(Popover)
  .use(Carousel)
  .use(CarouselItem)
  .use(Dialog)
  // .use(Message)
  .use(Loading)
  .use(Tabs);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox;
